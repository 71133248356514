const adminUiTexts = {
	switchToAdmin: {
		da: 'Skift til admin',
		en: 'Switch to admin',
		se: `Byt till admin`,
		de: `Zum Administrator wechseln`,
		pl: `Przełącz na administratora`,
		ro: `Comutați la admin`,
		ua: `Перейти до адміністратора`,
	},
	switchToFacilitator: {
		da: 'Skift til facilitator',
		en: 'Switch to facilitator',
		se: `Byt till facilitator`,
		de: `Zum Moderator wechseln`,
		pl: `Przełącz na moderatora`,
		ro: `Treceți la facilitator`,
		ua: `Переключитися на фасилітатора`,
	},
	viewCourse: {
		da: 'Se kursus',
		en: 'View course',
		se: `Se kursen`,
		de: `Kurs ansehen`,
		pl: `Zobacz kurs`,
		ro: `Vezi cursul`,
		ua: `Переглянути курс`,
	},
	addDepartment: {
		da: 'Tilføj %department%',
		en: 'Add %department%',
		se: `Lägg till %department%`,
		de: `%department% hinzufügen`,
		pl: `Dodaj %department%`,
		ro: `Adăugați %department%`,
		ua: `Додати %department%`,
	},
	addFacilitator: {
		da: 'Opret ny facilitator',
		en: 'Add new facilitator',
		se: `Lägg till ny facilitator`,
		de: `Neuen Moderator hinzufügen`,
		pl: `Dodaj nowego moderatora`,
		ro: `Adăugați un nou facilitator`,
		ua: `Додайте нового фасилітатора`,
	},
	downloadPlayers: {
		da: 'Download spillere',
		en: 'Download players',
		se: `Ladda ner spelare`,
		de: `Player herunterladen`,
		pl: `Pobierz odtwarzacze`,
		ro: `Descărcați jucători`,
		ua: `Завантажити плеєри`,
	},
	downloadStats: {
		da: 'Download statistik',
		en: 'Download statistics',
		se: 'Ladda ner statistik',
		de: 'Statistiken herunterladen',
		pl: 'Pobierz statystyk',
		ro: 'Descărcați statistici',
		ua: 'Завантажити статистики'
	},
	selectDepartment: {
		da: `Vælg %department%`,
		en: 'Choose %department%',
		se: `Välj %department%`,
		de: `Wählen Sie %department%`,
		pl: `Wybierz %department%`,
		ro: `Alege %department%`,
		ua: `Виберіть %department%`,
	},
	loadingPlayerStatuses: {
		da: 'Henter spillere',
		en: 'Loading players',
		se: 'Laddar spelare',
		de: 'Abruf von Spielern',
		pl: 'Pobieranie graczy',
		ro: 'Încărcare jucători',
		ua: 'Завантаження плеєрів'
	},
	loadingStats: {
		da: 'Henter statistik',
		en: 'Loading statistics',
		se: 'Laddar statistik',
		de: 'Lade Statistiken',
		pl: 'Ładowanie statystyk',
		ro: 'Se încarcă statistici',
		ua: 'Завантаження статистики'
	},

	/* Surveys */
	downloadSurveys: {
		da: 'Download surveys',
		en: 'Download surveys',
		se: 'Ladda ner undersökningar',
		de: 'Umfragen herunterladen',
		pl: 'Pobierz ankiety',
		ro: 'Descărcați sondaje',
		ua: 'Скачати опитування'
	},
	surveyResult: {
		da: 'Resultat',
		en: 'Result',
		se: 'Resultat',
		de: 'Resultat',
		pl: 'Rezultat',
		ro: 'Rezultat',
		ua: 'Результат'
	},
	noEnoughSurveyResponses: {
		da: 'Der er ikke nok svar til at vise resultatet endnu.',
		en: 'There are not enough responses yet to show the result.',
		se: `Det finns inte tillräckligt med svar ännu för att visa resultatet.`,
		de: `Es liegen noch nicht genügend Antworten vor, um das Ergebnis anzuzeigen.`,
		pl: `Nie ma jeszcze wystarczającej liczby odpowiedzi, aby wyświetlić wynik.`,
		ro: `Nu există încă suficiente răspunsuri pentru a arăta rezultatul.`,
		ua: `Ще недостатньо відповідей, щоб показати результат.`,
	},

	/* Add, edit, delete department */
	popupAddDepartment: {
		title: {
			da: 'Opret %department%',
			en: 'Add %department%',
			se: `Skapa %department%`,
			de: `%department% erstellen`,
			pl: `Utwórz %department%`,
			ro: `Creați %department%`,
			ua: `Створити %department%`,
		},
		toTierItemName: {
			da: 'til %tierItemName%',
			en: 'to %tierItemName%',
			se: 'to %tierItemName%',
			de: 'to %tierItemName%',
			pl: 'to %tierItemName%',
			ro: 'to %tierItemName%',
			ua: 'to %tierItemName%',
		},
		missingEmail: {
			da: 'Husk at vælge en facilitator.',
			en: 'Remember to choose a facilitator.',
			se: `Kom ihåg att välja en facilitator.`,
			de: `Denken Sie daran, einen Moderator auszuwählen.`,
			pl: `Pamiętaj o wyborze osoby prowadzącej.`,
			ro: `Nu uitați să alegeți un facilitator.`,
			ua: `Не забудьте вибрати фасилітатора.`,
		},
		sendEmail: {
			da: 'Send e-mail til facilitator',
			en: 'Send e-mail to facilitator',
			se: `Skicka e-post till handledare`,
			de: `E-Mail an Moderator senden`,
			pl: `Wyślij e-mail do koordynatora`,
			ro: `Trimite un e-mail facilitatorului`,
			ua: `Надіслати електронного листа фасилітатору`,
		},
		missingTierItem: {
			da: 'Husk at vælge niveauer',
			en: 'Remember to choose tiers',
			se: 'Kom ihåg att välja nivåer',
			de: 'Denken Sie daran, Ebenen auszuwählen',
			pl: 'Pamiętaj o wyborze poziomów',
			ro: 'Nu uitați să alegeți nivelurile',
			ua: 'Не забудьте вибрати рівні'
		},
		invalidTierItem: {
			da: 'Ugyldige tiers',
			en: 'Selected tier(s) are invalid',
			se: 'Valda nivåer är ogiltiga',
			de: 'Ausgewählte Stufe(n) sind ungültig',
			pl: 'Wybrane poziomy są nieprawidłowe',
			ro: 'Nivelurile selectate sunt nevalide',
			ua: 'Вибрані рівні (рівні) недійсні'
		}
	},
	popupEditDepartment: {
		title: {
			da: 'Rediger %department%',
			en: 'Edit %department%',
			se: `Redigera %department%`,
			de: `%department% bearbeiten`,
			pl: `Edytuj %department%`,
			ro: `Editați %department%`,
			ua: `Редагувати %department%`,
		},
		remove: {
			da: 'Fjern',
			en: 'Remove',
			se: `Avlägsna`,
			de: `Entfernen`,
			pl: `Usunąć`,
			ro: `Elimina`,
			ua: `видалити`,
		},
		addFacilitator: {
			da: 'Tilføj facilitator',
			en: 'Add facilitator',
			se: `Lägg till facilitator`,
			de: `Moderator hinzufügen`,
			pl: `Dodaj moderatora`,
			ro: `Adăugați un facilitator`,
			ua: `Додайте фасилітатора`,
		}
	},
	popupDeleteDepartment: {
		title: {
			da: 'Slet %department%',
			en: 'Delete %department%',
			se: `Ta bort %department%`,
			de: `%department% löschen`,
			pl: `Usuń %department%`,
			ro: `Șterge %department%`,
			ua: `Видалити %department%`,
		},
		text: {
			da: `Er du sikker på, du vil slette %nameDefSingular% %departmentName%?`,
			en: `Are you sure you want to delete %nameDefSingular% %departmentName%?`,
			se: `Är du säker på att du vill ta bort %nameDefSingular% %departmentName%?`,
			de: `Möchten Sie %nameDefSingular% %departmentName% wirklich löschen?`,
			pl: `Czy na pewno chcesz usunąć %nameDefSingular% %departmentName%?`,
			ro: `Sigur doriți să ștergeți %nameDefSingular% %departmentName%?`,
			ua: `Ви впевнені, що хочете видалити %nameDefSingular% %departmentName%?`,
		},
		text2: {
			da: `Der er %numberOfPlayers% spillere i %nameDefSingular% %departmentName%. For at slette %nameDefSingular%, skal du flytte dem til en anden %department%.`,
			en: `There are %numberOfPlayers% players in %nameDefSingular% %departmentName%. To delete %nameDefSingular% you have to move them to another %department%.`,
			se: `Det finns %numberOfPlayers% spelare i %nameDefSingular% %departmentName%. För att ta bort %nameDefSingular% måste du flytta dem till en annan %avdelning%.`,
			de: `Es gibt %numberOfPlayers% Spieler in %nameDefSingular% %departmentName%. Um %nameDefSingular% zu löschen, müssen Sie sie in eine andere %department% verschieben.`,
			pl: `W departamencie %nameDefSingular% %departmentName% jest %numberOfPlayers% graczy. Aby usunąć %nameDefSingular%, musisz przenieść ich do innego działu %department%.`,
			ro: `Există %numberOfPlayers% jucători în %nameDefSingular% %departmentName%. Pentru a șterge %nameDefSingular%, trebuie să le mutați în alt %department%.`,
			ua: `Є %numberOfPlayers% гравців у %nameDefSingular% %departmentName%. Щоб видалити %nameDefSingular%, вам потрібно перемістити їх до іншого %department%.`,
		},
		selectDepartment: {
			da: `Vælg %department%`,
			en: 'Choose %department%',
			se: `Välj %department%`,
			de: `Wählen Sie %department%`,
			pl: `Wybierz %department%`,
			ro: `Alege %department%`,
			ua: `Виберіть %department%`,
		},
		selectDepartmentErr: {
			da: `Du skal vælge en %department%, som du vil flytte spillerne fra denne %department% over til.`,
			en: `You have to choose a %department% to move the players from this %department% to.`,
			se: `Du måste välja en %department% för att flytta spelarna från denna %department% till.`,
			de: `Sie müssen eine %department% auswählen, in die die Spieler aus dieser %department% verschoben werden sollen.`,
			pl: `Musisz wybrać %department%, do którego chcesz przenieść graczy z tego %department%.`,
			ro: `Trebuie să alegeți un %department% în care să mutați jucătorii din acest %department%.`,
			ua: `Вам потрібно вибрати %department%, до якого потрібно перемістити гравців з цього %department%.`,
		}
	},

	/* Add or delete facilitator */
	popupAddFacilitator: {
		title: {
			da: 'Opret ny facilitator',
			en: 'Add new facilitator',
			se: `Lägg till ny facilitator`,
			de: `Neuen Moderator hinzufügen`,
			pl: `Dodaj nowego moderatora`,
			ro: `Adăugați un nou facilitator`,
			ua: `Додайте нового фасилітатора`,
		},
		email: {
			da: 'E-mail',
			en: 'E-mail',
			se: `E-post`,
			de: `E-mail`,
			pl: `E-mail`,
			ro: `E-mail`,
			ua: `Електронна пошта`,
		},
		missingEmail: {
			da: 'E-mail mangler',
			en: 'Missing e-mail',
			se: `E-post saknas`,
			de: `Fehlende E-Mail`,
			pl: `Brakujący email`,
			ro: `Lipsește e-mailul`,
			ua: `Відсутня електронна адреса`,
		},
		invalidEmail: {
			da: 'Ugyldig e-mail',
			en: 'Invalid e-mail',
			se: `Ogiltig e-postadress`,
			de: `Ungültige E-Mail`,
			pl: `Niepoprawny email`,
			ro: `E-mail invalid`,
			ua: `Недійсна електронна адреса`,
		},
		alreadyInvited: {
			da: 'Allerede tilføjet',
			en: 'Already added',
			se: `Redan tillagd`,
			de: `Schon hinzugefügt`,
			pl: `Już dodane`,
			ro: `Deja adaugat`,
			ua: `Вже додано`,
		},
		noDepartmentsSelected: {
			da: `Du skal vælge mindst 1 %department%`,
			en: `You have to select at least 1 %department%`,
			se: `Du måste välja minst 1 %department%`,
			de: `Sie müssen mindestens 1 %department% auswählen`,
			pl: `Musisz wybrać co najmniej 1 %department%`,
			ro: `Trebuie să selectați cel puțin 1 %department%`,
			ua: `Ви повинні вибрати принаймні 1 %department%`
		},
		couldNotSendWelcomeEmail: {
			da: 'Facilitator tilføjet, men velkomst-email blev ikke sendt.',
			en: 'Facilitator added but welcome e-mail was not sent.',
			se: `Handledare har lagts till men välkomstmail skickades inte.`,
			de: `Moderator hinzugefügt, aber Willkommens-E-Mail wurde nicht gesendet.`,
			pl: `Dodano moderatora, ale e-mail powitalny nie został wysłany.`,
			ro: `A fost adăugat facilitatorul, dar e-mailul de bun venit nu a fost trimis.`,
			ua: `Фасилітатора додано, але привітальний лист не надіслано.`,
		}
	},
	popupDeleteFacilitator: {
		title1: {
			da: 'Fjern facilitator',
			en: 'Remove facilitator',
			se: `Ta bort facilitator`,
			de: `Moderator entfernen`,
			pl: `Usuń moderatora`,
			ro: `Eliminați facilitatorul`,
			ua: `Видалити фасилітатора`,
		},
		title2: {
			da: 'Slet facilitator',
			en: 'Delete facilitator',
			se: `Ta bort facilitator`,
			de: `Moderator löschen`,
			pl: `Usuń moderatora`,
			ro: `Ștergeți facilitatorul`,
			ua: `Видалити фасилітатора`,
		},
		text1: {
			da: `Vil du fjerne %email% som facilitator?`,
			en: `Do you want to remove %email% as facilitator?`,
			se: `Vill du ta bort %email% som facilitator?`,
			de: `Möchten Sie %email% als Moderator entfernen?`,
			pl: `Czy chcesz usunąć %email% z funkcji moderatora?`,
			ro: `Doriți să eliminați %email% ca facilitator?`,
			ua: `Ви хочете видалити %email% як посередника?`,
		},
		text2: {
			da: `Vil du fjerne %email% som facilitator og slette deres bruger?`,
			en: `Do you want to remove %email% as facilitator and delete their user?`,
			se: `Vill du ta bort %email% som facilitator och ta bort deras användare?`,
			de: `Möchten Sie %email% als Moderator entfernen und deren Benutzer löschen?`,
			pl: `Czy chcesz usunąć użytkownika %email% jako moderatora i usunąć jego użytkownika?`,
			ro: `Doriți să eliminați %email% ca facilitator și să ștergeți utilizatorul?`,
			ua: `Ви хочете видалити %email% як фасилітатора та видалити його користувача?`,
		}
	},

	/* Import players */
	popupImportPlayers: {
		importDepartment: {
			da: 'Importer %departments% / niveauer',
			en: 'Import %departments% / tiers',
			se: `Importera %departments% / tiers`,
			de: `%departments% / Stufen Import`,
			pl: `Importuj %departments% / poziomy`,
			ro: `Importă %departments% / tiers`,
			ua: `Імпорт %departments% / Рівні`,
		},
		chooseDepartment: {
			da: 'Vælg %department%',
			en: 'Choose %department%',
			se: `Välj %department%`,
			de: `Wählen Sie %department%`,
			pl: `Wybierz %department%`,
			ro: `Alege %department%`,
			ua: `Виберіть %department%`,
		},
		chooseTiers: {
			da: 'Vælg niveauer',
			en: 'Choose tiers',
			se: `Välj tiers`,
			de: `Wählen Sie Stufen`,
			pl: `Wybierz poziomy`,
			ro: `Alege tiers`,
			ua: `Виберіть Рівні`,
		},
		choosePlayerInfo: {
			da: 'Vælg spillerinfo',
			en: 'Choose player info',
			se: 'Välj spelarinformation',
			de: 'Spielerinfo auswählen',
			pl: 'Wybierz informacje o graczu',
			ro: 'Alegeți informațiile despre jucător',
			ua: 'Виберіть інформацію про гравця',
		},
		noDepartment: {
			da: 'Du skal først vælge en %department%.',
			en: 'You have to choose a %department% first.',
			se: `Du måste först välja en %department%.`,
			de: `Sie müssen zuerst eine %department% auswählen.`,
			pl: `Najpierw musisz wybrać %department%.`,
			ro: `Mai întâi trebuie să alegeți un %department%.`,
			ua: `Спочатку вам потрібно вибрати %department%.`,
		},
		invalidDepartmentOrTiers: {
			da: 'Ugyldig %department% / niveauer',
			en: 'Invalid %department% / tiers',
			se: 'Ogiltig %department% / tiers',
			de: 'Ungültige %department% / Stufen',
			pl: 'Nieprawidłowy %department% / poziomy',
			ro: '%department% & tiers nevalid',
			ua: 'Недійсний %department% / Рівні'
		},
		existingPlayersNewDepartment: {
			da: 'Eksisterende spillere, ny %department%',
			en: 'Existing players, new %department%',
			se: 'Befintliga spelare, ny %department%',
			de: 'Bestehende Spieler, neue %department%',
			pl: 'Istniejący gracze, nowy %department%',
			ro: 'Jucători existenți, %department% noi',
			ua: 'Наявні гравці, новий %department%',
		},
		fromDepartment: {
			da: 'Fra %department%',
			en: 'From %department%',
			se: 'Från %department%',
			de: 'Von %department%',
			pl: 'Z %department%',
			ro: 'De la %department%',
			ua: 'Від %department%'
		},
		toDepartment: {
			da: 'Til %department%',
			en: 'To %department%',
			se: 'Till %department%',
			de: 'An %department%',
			pl: 'Do %department%',
			ro: 'Către %department%',
			ua: 'До %department%'
		}
	}
};

export {
	adminUiTexts
};