const taskUiTexts = {
	task: {
		da: 'Opgave',
		en: 'Task',
		se: `Uppgift`,
		de: `Aufgabe`,
		pl: `Zadanie`,
		ro: `Sarcină`,
		ua: `Завдання`,
		tr: 'Görev'
	},
	taskId: {
		da: 'Opgave-id',
		en: 'Task id',
		se: `Uppgifts-id`,
		de: `Aufgaben-ID`,
		pl: `Identyfikator zadania`,
		ro: `ID sarcină`,
		ua: `ID завдання`,
		tr: 'Görev kimliği'
	},
	viewTask: {
		da: 'Se opgave',
		en: 'View task',
		se: `Visa uppgift`,
		de: `Zuweisung anzeigen`,
		pl: `Wyświetlanie przypisania`,
		ro: `Vizualizarea atribuirii`,
		ua: `Переглянути завдання`,
		tr: 'Görevi görüntüle'
	},
	type: {
		da: 'Type',
		en: 'Type',
		se: `Typ`,
		de: `Art`,
		pl: `Typ`,
		ro: `Tip`,
		ua: `Тип`,
		tr: 'Tip'
	},
	question: {
		da: 'Spørgsmål',
		en: 'Question',
		se: `Fråga`,
		de: `Frage`,
		pl: `Pytanie`,
		ro: `Întrebare`,
		ua: `Питання`,
		tr: 'Soru'
	},
	answerOptions: {
		da: 'Svarmuligheder',
		en: 'Answer options',
		se: 'Svarsalternativ',
		de: 'Antwortmöglichkeiten',
		pl: 'Opcje odpowiedzi',
		ro: 'Opțiuni de răspuns',
		ua: 'Варіанти відповідей',
		tr: 'Yanıt seçenekleri'
	},
	answers: {
		da: 'Svar',
		en: 'Answers',
		se: 'Svar',
		de: 'Antworten',
		pl: 'Odpowiedzi',
		ro: 'Răspunsuri',
		ua: 'Відповіді',
		tr: 'Cevaplar'
	}
};

export {
	taskUiTexts
};