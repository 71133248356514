const languagesData = [
	{
		id: 'da',
		title: 'Dansk', 
		shortTitle: 'DA',
		localization: 'da-DK'
	},
	{
		id: 'en',
		title: 'English',
		shortTitle: 'EN',
		localization: 'en-GB'
	},
	{
		id: 'se',
		title: 'Svenska',
		shortTitle: 'SE',
		localization: 'sv-SE'
	},
	{
		id: 'de',
		title: 'Deutsch',
		shortTitle: 'DE',
		localization: 'de-DE'
	},
	{
		id: 'pl',
		title: 'Polski',
		shortTitle: 'PL',
		localization: 'pl-PL'
	},
	{
		id: 'ro',
		title: 'Română',
		shortTitle: 'RO',
		localization: 'ro-RO'
	},
	{
		id: 'ua',
		title: 'Yкраїнська',
		shortTitle: 'UKR',
		localization: 'uk-UA'
	},
	{
		id: 'hu',
		title: 'Magyar',
		shortTitle: 'HU',
		localization: 'hu-HU'
	},
	{
		id: 'ru',
		title: 'русский',
		shortTitle: 'RU',
		localization: 'ru-RU'
	},
	{
		id: 'vi',
		title: 'Tiếng Việt',
		shortTitle: 'VN',
		localization: 'vi-VN'
	},
	{
		id: 'et',
		title: 'Eesti keel',
		shortTitle: 'EE',
		localization: 'et-EE'
	},
	{
		id: 'tr',
		title: 'Türkçe',
		shortTitle: 'TR',
		localization: 'tr-TR'
	},
];

export {languagesData};