let env = process.env.REACT_APP_ENV
	? process.env.REACT_APP_ENV
	: process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let siteUrl = 'localhost:3000';
let qrUrl = 'https://localhost:3000';
let apiURL = 'http://127.0.0.1:5001/cgl-compliance-test/us-central1/app/api/';
let platformDbName = 'platform';
let companiesDbName = 'companies';
let coursesDbName = 'courses';
let modulesDbName = 'modules';
let tasksDbName = 'tasks';
let surveysDbName = 'surveys';
let userCoursesDbName = 'user-courses';
let audioFolder = 'audio';
let companiesFolder = 'companies';
let coursesFolder = 'courses';
if (env === 'test' || env === 'demo') {
	siteUrl = 'cgl-compliance-test.web.app';
	qrUrl = 'https://cgl-compliance-test.web.app';
	apiURL = 'https://cgl-compliance-test.web.app/api/';
	if (env === 'demo') {
		companiesDbName = 'companies-demo';
		coursesDbName = 'courses-demo';
		modulesDbName = 'modules-demo';
		tasksDbName = 'tasks-demo';
		surveysDbName = 'surveys-demo';
		userCoursesDbName = 'user-courses-demo';
		audioFolder = 'audio-demo';
		companiesFolder = 'companies-demo';
		coursesFolder = 'courses-demo';
		siteUrl = 'cgl-compliance-demo.web.app';
		qrUrl = 'https://cgl-compliance-demo.web.app';
		apiURL = 'https://cgl-compliance-demo.web.app/api/';

	}
}
if (env === 'production') {
	siteUrl = 'traininggames.dk';
	qrUrl = 'https://traininggames.dk';
	apiURL = 'https://cgl-compliance-production.web.app/api/';
}

const appConfig = {
	env,
	siteUrl,
	qrUrl,
	apiURL,
	platformDbName,
	companiesDbName,
	coursesDbName,
	modulesDbName,
	tasksDbName,
	surveysDbName,
	userCoursesDbName,
	usersDbName: 'users',
	audioFolder,
	companiesFolder,
	coursesFolder,
	cglUrl: 'https://cphgamelab.dk/',
	inactivityLimitSeconds: 30,
	maxUsersPerBackendQuery: 1000,
	tierIdLength: 4,
	tierItemIdLength: 4,
	eventWarningIdLength: 5,
	defaultLanguage: 'da',
	showDevTools: (env === 'development' || env === 'test'),
	name: 'Compliance Platform',
	cookiesAcceptedCookieName: 'compliance_cookies_accepted',
	phoneNumber: '+45 70 70 22 27',

	courseImagesFolder: 'images',
	courseDefaultStyleFolder: 'default'

};

export default appConfig;