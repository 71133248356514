const gameUiTexts = {
	logout: {
		da: 'Log ud',
		en: 'Log out',
		se: 'Logga ut',
		de: 'Ausloggen',
		pl: 'Wyloguj się',
		ro: 'Deconectare',
		ua: 'вийти',
		vi: `Đăng xuất`,
		hu: `Jelentkezzen ki`,
		ru: `Выход из системы`,
		et: 'Logi välja',
		tr: 'Çıkış Yap'
	},

	switchCourse: {
		da: 'Skift kursus',
		en: 'Switch course',
		se: 'Ändra kurs',
		de: 'Kurs wechseln',
		pl: 'Zmień kurs',
		ro: 'Schimbați cursul',
		ua: 'Змінити курс',
		vi: `Chuyển khóa học`,
		hu: `Válts kurzust`,
		ru: `Изменить курс`,
		et: 'Vaheta kurssi',
		tr: 'Yön değiştir'
	},

	useDevTools: {
		da: 'Brug dev tools',
		en: 'Use dev tools',
		se: 'Använd dev-verktyg',
		de: 'Verwenden Sie Dev-Tools',
		pl: 'Użyj narzędzi deweloperskich',
		ro: 'Utilizați instrumente de dezvoltare',
		ua: 'Використовуйте інструменти розробника',
		vi: `Sử dụng công cụ phát triển`,
		hu: `Használj fejlesztői eszközöket`,
		ru: `Используйте инструменты разработки`,
		et: 'Kasutage arendaja tööriistu',
		tr: 'Geliştirici araçlarını kullanın'	
	},

	reset: {
		da: 'Nulstil',
		en: 'Reset',
		se: 'Återställa',
		de: `Zurücksetzen`,
		pl: `Zresetuj`,
		ro: `Resetați`,
		ua: `Скинути`,
		vi: `Cài lại`,
		hu: `Reset`,
		ru: `Сброс`,
		et: 'Lähtesta',
		tr: 'Sıfırla'
	},

	chooseLanguage: {
		da: 'Vælg sprog',
		en: 'Choose language',
		se: 'Välj språk',
		pl: 'Wybierz język',
		de: 'Sprache wählen',
		ro: 'Selecteaza limba',
		ua: 'Оберіть мову',
		vi: `Chọn ngôn ngữ`,
		hu: `Válassz nyelvet`,
		ru: `Выберите язык`,
		et: 'Valige keel',
		tr: 'Dil seçin'
	},
};

export {
	gameUiTexts
};