import React from 'react';
import {marked} from 'marked';
import {uiTexts} from 'data/ui-texts';
import {checkIfObjectIsArray} from 'helpers/general-helper';

/**
 * Parse markdown text
 * @param {string} marked
 * @return {html}
 */
const renderMarkdown = (markdown) => {
	if (markdown && typeof markdown === 'string' && markdown.length > 0) {
		const lexed = marked.lexer(markdown);
		const parsed = marked.parser(lexed);
		return <div dangerouslySetInnerHTML={{__html: parsed}} />;
	}
	return <div />;
};

/**
 * Get text
 * @param {string} text 
 * @param {string} languageId 
 * @param {array} replacements
 * @returns 
 */
const getText = (text, languageId, replacements = []) => {
	let uiText = '';

	/* Get ui text for language */
	if (typeof text === 'object') {
		/* Text is object */
		if (text && text.hasOwnProperty(languageId)) {
			uiText = text[languageId];
		}
	} else if (typeof text === 'string') {
		/* Text is string */
		if (text && text.length > 0) {
			const textIdArray = text.split('_');
			if (textIdArray.length > 1) {
				let textObj = uiTexts;
				textIdArray.forEach((property) => {
					if (textObj.hasOwnProperty(property)) {
						textObj = textObj[property];
					} else {
						console.error(text, property);
					}
				});
				if (textObj.hasOwnProperty(languageId)) {
					uiText = textObj[languageId];
				}
			} else {
				uiText = text;
			}
		}
	}


	/* Replace placeholder texts */
	if (uiText && uiText.length > 0 && replacements.length > 0) {
		replacements.forEach((replacement) => {
			if (replacement.length === 2 && replacement[1] && replacement[1].length > 0) {
				uiText = uiText.replace(new RegExp(replacement[0], 'g'), replacement[1]);
			}
		});
	}
	
	return uiText;
};


/**
 * Find all text objects in an array / obj
 * @param {object} obj 
 * @returns 
 */
const getTextsObjs = (obj) => {
	let result = [];

	if (typeof obj !== 'object') {
		/* Obj is not an object / array */
		return result;
	}

	if (checkIfObjectIsArray(obj)) {
		/* Obj is array */
		obj.forEach((item) => {
			const textObjs = getTextsObjs(item);
			if (textObjs && textObjs.length > 0) {
				textObjs.forEach((tObj) => {
					result.push(tObj);
				});	
			}
		});
	} else {
		/* Obj is object, check if it has texts */
		if (obj.hasOwnProperty('da') || obj.hasOwnProperty('en')) {
			/* Obj is text object */
			result = [obj];
		} else {
			/* Obj is NOT text object */
			for (const [, value] of Object.entries(obj)) {
				const textObjs = getTextsObjs(value);
				if (textObjs && textObjs.length > 0) {
					textObjs.forEach((tObj) => {
						result.push(tObj);
					});	
				}
			}
		}
	}
	return result;
};


export {
	renderMarkdown,
	getText,
	getTextsObjs
};