const playerUiTexts = {
	/* Tier */
	selectTier: {
		da: 'Vælg %tiersLabelSingular%',
		en: 'Select %tiersLabelSingular%',
		se: 'Välj %tiersLabelSingular%',
		de: '%tiersLabelSingular% auswählen',
		pl: 'Wybierz %tiersLabelSingular%',
		ro: 'Selectați %tiersLabelSingular%',
		ua: 'Виберіть %tiersLabelSingular%',
	},

	/* Department */
	selectDepartment: {
		da: 'Vælg %departmentsLabelSingular%',
		en: 'Select %departmentsLabelSingular%',
		se: 'Välj %departmentsLabelSingular%',
		de: '%departmentsLabelSingular% auswählen',
		pl: 'Wybierz %departmentsLabelSingular%',
		ro: 'Selectați %departmentsLabelSingular%',
		ua: 'Виберіть %departmentsLabelSingular%',
	},
	switchDepartment: {
		da: 'Skift %departmentsLabelSingular%',
		en: 'Switch %departmentsLabelSingular%',
		se: 'Ändra %departmentsLabelSingular%',
		de: '%departmentsLabelSingular% wechseln',
		pl: 'Zmień %departmentsLabelSingular%',
		ro: 'Schimba %departmentsLabelSingular%',
		ua: 'комутаційний %departmentsLabelSingular%',
	},

	/* Info */
	infoTitle: {
		da: 'Velkommen!',
		en: 'Welcome!',
		se: 'Välkommen!',
		de: 'Willkommen!',
		pl: 'Powitanie!',
		ro: 'Bine ati venit!',
		ua: 'Ласкаво просимо!',
	},
	infoText: {
		da: 'Vi skal bruge lidt information om dig. Den bliver kun vist for de personer, der faciliterer de spil, du spiller.',
		en: 'We need a little information about you. It will only be available to the people who facilitate the games you play.',
		se: 'Vi behöver lite information om dig. Det kommer bara att visas för de personer som underlättar de spel du spelar.',
		de: 'Wir benötigen einige Informationen über Sie. Diese sind nur den Personen zugänglich, die die von Ihnen gespielten Spiele organisieren.',
		pl: 'Potrzebujemy trochę informacji o Tobie. Będą one dostępne tylko dla osób, które ułatwiają gry, w które grasz.',
		ro: 'Avem nevoie de puține informații despre tine. Va fi disponibil doar persoanelor care facilitează jocurile pe care le jucați.',
		ua: 'Нам потрібна трохи інформації про вас. Він буде доступний лише для людей, які сприяють іграм, у які ви граєте.',
	},

	/* Course */
	selectCourse: {
		da: 'Vælg kursus',
		en: 'Select course',
		se: 'Välj kurs',
		de: 'Kurs auswählen',
		pl: 'Wybierz kurs',
		ro: 'Selectați cursul',
		ua: 'Виберіть курс',
	},
	noCoursesDepartment: {
		da: 'Denne %department% har ikke adgang til nogen kurser endnu.',
		en: 'This %department% does not have access to any courses yet.',
		se: 'Denna %department% har inte tillgång till några kurser än.',
		de: 'Diese %department% hat noch keinen Zugriff auf Kurse.',
		pl: 'Ten %department% nie ma jeszcze dostępu do żadnych kursów.',
		ro: 'Acest %departament% nu are încă acces la niciun curs.',
		ua: 'Цей %department% ще не має доступу до жодних курсів.',
	}
};

export {
	playerUiTexts
};