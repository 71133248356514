const moduleUiTexts = {
	moduleId: {
		da: 'Modul-id',
		en: 'Module id',
		se: 'Modul-id',
		de: 'Modul-ID',
		pl: 'Identyfikator modułu',
		ro: 'ID modul',
		ua: 'Ідентифікатор модуля',
		tr: 'Modül kimliği'
	},
	module: {
		da: 'Modul',
		en: 'Module',
		se: 'Modul',
		de: 'Modul',
		pl: 'Modułu',
		ro: 'Modul',
		ua: 'модуля',
		tr: 'Modül'
	},
	name: {
		da: 'Navn',
		en: 'Name',
		se: `Namn`,
		de: `Name`,
		pl: `Nazwa`,
		ro: `Nume`,
		ua: `Ім'я`,
		tr: 'İsim'
	},
	voice: {
		da: 'Stemme',
		en: 'Voice',
		se: `Röst`,
		de: `Stimme`,
		pl: `Głos`,
		ro: `Voce`,
		ua: `Голос`,
		tr: 'Ses'
	},
	moduleGroup: {
		da: 'Modulgruppe',
		en: 'Module group',
		se: `Grupp av moduler`,
		de: `Modulgruppe`,
		pl: `Grupa modułów`,
		ro: `Grup de module`,
		ua: `Група модулів`,
		tr: 'Modül grubu'
	},
	background: {
		da: 'Baggrund',
		en: 'Background',
		se: `Bakgrund`,
		de: `Hintergrund`,
		pl: `Tło`,
		ro: `Fond`,
		ua: `Тло`,
		tr: 'Arka Plan'
	},
	access: {
		da: 'Adgang',
		en: 'Access',
		se: `Tillträde`,
		de: `Zugang`,
		pl: `Dostęp`,
		ro: `Acces`,
		ua: `Доступ`,
		tr: 'Erişim'
	},
	requiredModules: {
		da: 'Krævede moduler',
		en: 'Required modules',
		se: `Nödvändiga moduler`,
		de: `Erforderliche Module`,
		pl: `Wymagane moduły`,
		ro: `Module necesare`,
		ua: `Необхідні модулі`,
		tr: 'Gerekli modüller'
	},
	stars: {
		da: 'Stjerner',
		en: 'Stars',
		se: `Stjärnor`,
		de: `Sterne`,
		pl: `Gwiazdki`,
		ro: `Stele`,
		ua: `Зірки`,
		tr: 'Yıldızlar'
	},
	starsMax: {
		da: 'Max',
		en: 'Max',
		se: `Max`,
		de: `Max`,
		pl: `Max`,
		ro: `Max`,
		ua: `Макс`,
		tr: 'Maksimum'
	},
	starType: {
		da: 'Type',
		en: 'Type',
		se: `Typ`,
		de: `Art`,
		pl: `Typ`,
		ro: `Tip`,
		ua: `Тип`,
		tr: 'Tür'
	},
	starsToPass: {
		da: 'Krævet',
		en: 'Required',
		se: `Krävs`,
		de: `Erforderlich`,
		pl: `Wymagane`,
		ro: `Necesar`,
		ua: `Необхідний`,
		tr: 'Gerekli'
	},
	starsPerPercentPoints: {
		da: 'Per % point',
		en: 'Per % points',
		se: `Per %-enheter`,
		de: `Pro %-Punkte`,
		pl: `Za punkt procentowy`,
		ro: `Per punct procentual`,
		ua: `За % пунктів`,
		tr: 'Yüzde puan başına'
	},
	attempts: {
		da: 'Forsøg',
		en: 'Attempts',
		se: `Försök`,
		de: `Versuchen`,
		pl: `Próba`,
		ro: `Încercare`,
		ua: `Спроба`,
		tr: 'Denemeler'
	},
	tasks: {
		da: 'Opgaver',
		en: 'Tasks',
		se: `Uppgifter`,
		de: `Aufgaben`,
		pl: `Zadania`,
		ro: `Sarcini`,
		ua: `Завдання`,
		tr: 'Görevler'
	}
};

export {
	moduleUiTexts
};