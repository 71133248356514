const statsUiTexts = {
	stats: {
		da: 'Stats',
		en: 'Stats',
		se: `Statistik`,
		de: `Statistiken`,
		pl: `Statystyki`,
		ro: `Statistici`,
		ua: `статистика`,
	},
	statistics: {
		da: 'Statistik',
		en: 'Statistics',
		se: `Statistik`,
		de: `Statistiken`,
		pl: `Statystyka`,
		ro: `Statistici`,
		ua: `Статистика`,
	},

	started: {
		da: 'Startet',
		en: 'Started',
		se: `Satte igång`,
		de: `Gestartet`,
		pl: `Rozpoczęty`,
		ro: `A început`,
		ua: `розпочато`,
	},
	passed: {
		da: 'Gennemført',
		en: 'Passed',
		se: `Genomfört`,
		de: `Umgesetzt`,
		pl: `Wdrożone`,
		ro: `Implementat`,
		ua: `Реалізовано`,
	},
	notPassed: {
		da: 'Ikke gennemført',
		en: 'Not passed',
		se: `Ej genomförd`,
		de: `Nicht vollständig`,
		pl: `Nie skończone`,
		ro: `Incomplet`,
		ua: `Не завершено`,
	},

	attempts: {
		da: 'Forsøg',
		en: 'Attempts',
		se: `Försök`,
		de: `Versuche`,
		pl: `Próbowanie`,
		ro: `Încercări`,
		ua: `Спроби`,
	},

	errorsAvg: {
		da: 'Fejl (gns)',
		en: 'Errors (avg)',
		se: `Fel (gen.)`,
		de: `Fehler (Durchschnitt)`,
		pl: `Błędy (śr.)`,
		ro: `Erori (medie)`,
		ua: `Помилки (середнє)`,
	},
	numberOfErrorsAvg: {
		da: 'Antal fejl (gns)',
		en: 'Errors (avg)',
		se: `Fel (gen.)`,
		de: `Fehler (Durchschnitt)`,
		pl: `Błędy (śr.)`,
		ro: `Erori (medie)`,
		ua: `Помилки (середнє)`,
	},
	errorsPerTaskAvg: {
		da: 'Fejl pr. opgave (gns)',
		en: 'Errors per task (avg)',
		se: `Fel per uppgift (gen.)`,
		de: `Fehler pro Aufgabe (Durchschnitt)`,
		pl: `Błędy na zadanie (śr.)`,
		ro: `Erori per sarcină (medie)`,
		ua: `Помилки на завдання (середнє)`,
	},

	timeSpent: {
		da: 'Tid brugt',
		en: 'Time spent',
		se: `Spenderad tid`,
		de: `Zeitaufwand`,
		pl: `Czas spędzony`,
		ro: `Timp petrecut`,
		ua: `Витрачений час`,
	},
	timeSpentAvg: {
		da: 'Tid brugt (gns)',
		en: 'Time spent (avg)',
		se: `Spenderad tid (gen.)`,
		de: `Zeitaufwand (Durchschnitt)`,
		pl: `Czas spędzony (śr.)`,
		ro: `Timp petrecut (medie)`,
		ua: `Витрачений час (середнє)`,
	},
	easiestTasks: {
		da: 'Nemmeste opgaver',
		en: 'Easiest tasks',
		se: `Easiest tasks`,
		de: `Easiest tasks`,
		pl: `Easiest tasks`,
		ro: `Easiest tasks`,
		ua: `Easiest tasks`,
	},
	hardestTasks: {
		da: 'Sværeste opgaver',
		en: 'Hardest tasks',
		se: `Hardest tasks`,
		de: `Hardest tasks`,
		pl: `Hardest tasks`,
		ro: `Hardest tasks`,
		ua: `Hardest tasks`,
	}
	
};

export {
	statsUiTexts
};