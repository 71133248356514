import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {getText} from 'helpers/text-helper';
import {renderMarkdown} from 'helpers/text-helper';
import './select.scss';
import './select-cpanel.scss';
import './select-admin.scss';
import './select-facilitator.scss';
import './select-language.scss';

const Select = (props) => {
	/* Props */
	const {
		isDisabled = false, 
		showImage = false,
		useShortTitleForSelected = false,
		languageId, 
		type,
		placeholderText, 
		defaultId, 
		selectedId, 
		options, 
		classes, 
		onSelect
	} = props;

	/* Dropdown */
	let [dropdownIsOpen, setDropDown] = useState(false);
	const toggleDropdown = () => {
		if (isDisabled) return;
		setDropDown(!dropdownIsOpen);
	};

	/**
	 * Get displayed value of option
	 * @param {object} option 
	 * @returns 
	 */
	const getOptionValue = (option, useShortTitle) => {
		let value = option.id;
		if (option.title) {
			if (typeof option.title === 'string' || (useShortTitle && typeof option.shortTitle === 'string')) {
				value = useShortTitle ? option.shortTitle : option.title;
			} else {
				value = getText(option.title, languageId);
			}
		} else if (option.name) {
			if (typeof option.name === 'string') {
				value = option.name;
			} else {
				value = getText(option.name, languageId);
			}
		}
		if (value === null || value === undefined) {
			value = '';
		}

		return value;
	};

	/**
	 * Select option
	 * @param {string} optionId 
	 * @returns 
	 */
	const handleSelect = (optionId) =>  {
		if (isDisabled) return;
		onSelect(optionId);
		toggleDropdown();
	};

	/* Get selected option / default option / placeholder text */
	let selectedOption = null;
	if (selectedId && options.some((option) => {return option.id === selectedId;})) {
		selectedOption = options.find((option) => {return option.id === selectedId;});
	} else if (defaultId && options.some((option) => {return option.id === defaultId;})) {
		selectedOption = options.find((option) => {return option.id === defaultId;});
	} else {
		selectedOption = (placeholderText 
			? {id: getText(placeholderText, languageId)}
			: {id: getText('button_choose', languageId)}
		);
	}

	/* Get class name and modifiers */
	let className = 'Select' 
		+ (type ? ' ' + type : '') 
		+ (dropdownIsOpen ? ' open' : '')
		+ (isDisabled ? ' disabled' : '')
	;

	/* Add custom class modifiers */
	if (classes && classes.length > 0) {
		classes.forEach((c) => {if (c.length > 0) className = className + ' ' + c;});
	}

	return (
		<div className={className}>
			<div className={'Select-panel ' + selectedId} onClick={()=>{toggleDropdown();}}>
				<div className="Select-selected">
					<span>{getOptionValue(selectedOption, useShortTitleForSelected)}</span>
				</div>
				<div className="Select-button" />
			</div>
			<div className="Select-dropdown">
				<div className="Select-options">
					{options.map((option, index) => {
						const isDisabled = (option.hasOwnProperty('isDisabled') && option.isDisabled === true);
						const className = 'Select-option' 
							+ (selectedId === option.id ? ' selected' : '')
							+ (isDisabled ? ' disabled' : '');
						return (
							<div 
								key={index} 
								className={className} 
								onClick={()=>{
									if (!isDisabled) {
										if (option.id) {
											handleSelect(option.id);
										} else {
											handleSelect(option);
										}
									}
								}}
							>
								{renderMarkdown(getOptionValue(option))}
								{(showImage && option.url) && <img src={option.url} alt='img' />}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};


Select.propTypes = {
	isDisabled: PropTypes.bool,
	showImage: PropTypes.bool,
	useShortTitleForSelected: PropTypes.bool,
	languageId: PropTypes.string.isRequired,
	type: PropTypes.string,
	placeholderText: PropTypes.string,
	defaultId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	selectedId: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number
	]),
	options: PropTypes.array.isRequired,
	classes: PropTypes.array,
	onSelect: PropTypes.func.isRequired,
};

export default Select;