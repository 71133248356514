const courseUiTexts = {
	courseInfo: {
		da: 'Kursusinfo',
		en: 'Course info',
		se: `Kursinfo`,
		de: `Informationen zum Kurs`,
		pl: `Informacje o kursie`,
		ro: `Informații despre curs`,
		ua: `Інформація про курс`,
	},
	courseId: {
		da: 'Kursus-id',
		en: 'Course id',
		se: 'Kurs-id',
		de: 'Kurs-ID',
		pl: 'Identyfikator kursu',
		ro: 'ID curs',
		ua: 'ID курсу'
	},
	courseName: {
		da: 'Kursusnavn',
		en: 'Course name',
		se: `Kursens namn`,
		de: `Name des Kurses`,
		pl: `Nazwa kursu`,
		ro: `Numele cursului`,
		ua: `Назва курсу`,
	},
	courseGroup: {
		da: 'Kursusgruppe',
		en: 'Course group',
		se: 'Kursgrupp',
		de: 'Kursgruppe',
		pl: 'Grupa kursowa',
		ro: 'Grup de curs',
		ua: 'Курсова група'
	},
	language: {
		da: 'Sprog',
		en: 'Language',
		se: `Språk`,
		de: `Sprache`,
		pl: `Język`,
		ro: `Limbă`,
		ua: `Мова`,
	},
	languages: {
		da: 'Sprog',
		en: 'Languages',
		se: `Språk`,
		de: `Sprachen`,
		pl: `Języki`,
		ro: `Limbi`,
		ua: `Мови`,
	},
	defaultLanguage: {
		da: 'Default sprog',
		en: 'Default language',
		se: `Standardspråk`,
		de: `Standardsprache`,
		pl: `Język domyślny`,
		ro: `Limba implicită`,
		ua: `Мова за замовчуванням`,
	},
	companyStyleId: {
		da: 'Firmastil',
		en: 'Company style',
		se: `Företagets stil`,
		de: `Unternehmensstil`,
		pl: `Styl firmy`,
		ro: `Stilul companiei`,
		ua: `Стиль компанії`,
	},
	companySubStyleId: {
		da: 'Firma sub-stil',
		en: 'Company sub style',
		se: `Företagets understil`,
		de: `Unternehmens-Substil`,
		pl: `Podstyl firmy`,
		ro: `Sub-stil de companie`,
		ua: `Підстиль компанії`,
	},
	progressTypeId: {
		da: 'Spilflow',
		en: 'Game flow',
		se: `Spelets flöde`,
		de: `Spielablauf`,
		pl: `Przebieg gry`,
		ro: `Fluxul jocului`,
		ua: `Хід гри`,
	},
	initialModuleGroup: {
		da: 'Første modulgruppe',
		en: 'Initial module groups',
		se: `Inledande modulgrupper`,
		de: `Anfängliche Modulgruppen`,
		pl: `Początkowe grupy modułów`,
		ro: `Grupuri inițiale de module`,
		ua: `Початкові групи модулів`,
	},
	name: {
		da: 'Navn',
		en: 'Name',
		se: `Namn`,
		de: `Name`,
		pl: `Nazwa`,
		ro: `Nume`,
		ua: `Ім'я`,
	},
	type: {
		da: 'Type',
		en: 'Type',
		se: `Typ`,
		de: `Art`,
		pl: `Typ`,
		ro: `Tip`,
		ua: `Тип`,
	},
	style: {
		da: 'Stil',
		en: 'Style',
		se: `Stil`,
		de: `Stil`,
		pl: `Styl`,
		ro: `Stil`,
		ua: `Стиль`,
	},
	url: {
		da: 'URL',
		en: 'URL',
		se: `URL`,
		de: `URL`,
		pl: `URL`,
		ro: `URL`,
		ua: `URL`,
	},
	created: {
		da: 'Oprettet',
		en: 'Created',
		se: `Skapad`,
		de: `Erstellt`,
		pl: `Utworzono`,
		ro: `Creat`,
		ua: `Створений`,
	},
	createdBy: {
		da: 'Oprettet af',
		en: 'Created by',
		se: `Skapad av`,
		de: `Erstellt von`,
		pl: `Utworzony przez`,
		ro: `Creat de`,
		ua: `Створено в`,
	},
	updated: {
		da: 'Sidst opdateret',
		en: 'Last updated',
		se: `Senast uppdaterad`,
		de: `Zuletzt aktualisiert`,
		pl: `Ostatnia aktualizacja`,
		ro: `Ultima actualizare`,
		ua: `Останнє оновлення`,
	},
	downloaded: {
		da: 'Download dato',
		en: 'Download date',
		se: `Ladda ned datum`,
		de: `Datum herunterladen`,
		pl: `Data pobrania`,
		ro: `Data descarcarii`,
		ua: `Дата завантаження`
	},
	uploaded: {
		da: 'Uploaded',
		en: 'Uploaded',
		se: 'Uppladdad',
		de: 'Hochgeladen',
		pl: 'Załadowany',
		ro: 'Încărcat',
		ua: 'Завантажено',
		
	},
	facilitators: {
		da: 'Facilitatorer',
		en: 'Facilitators',
		se: `Kontaktpersoner`,
		de: `Moderatoren`,
		pl: `Mediatorzy`,
		ro: `Facilitatori`,
		ua: `Посередників`,
	},
	players: {
		da: 'Spillere',
		en: 'Players',
		se: `Spelare`,
		de: `Spieler`,
		pl: `Graczy`,
		ro: `Jucători`,
		ua: `Гравців`,
	},
	allPlayers: {
		da: 'Alle spillere',
		en: 'All players',
		se: `Alla spelare`,
		de: `Alle Spieler`,
		pl: `Wszyscy gracze`,
		ro: `Toți jucătorii`,
		ua: `Всі гравці`,
	},
	passedLatest: {
		da: 'Seneste bestået modul',
		en: 'Latest passed module',
		se: `Senast godkända modul`,
		de: `Zuletzt bestandenes Modul`,
		pl: `Ostatnio zaliczony moduł`,
		ro: `Ultimul modul trecut`,
		ua: `Останній пройдений модуль`,
	},
	passedCourse: {
		da: 'Bestået kursus',
		en: 'Passed course',
		se: `Godkänd kurs`,
		de: `Bestandener Kurs`,
		pl: `Zdany kurs`,
		ro: `Curs promovat`,
		ua: `Пройдений курс`,
	},
	notStarted: {
		da: 'Ikke påbegyndt',
		en: 'Not started',
		se: `Inte påbörjad`,
		de: `Nicht gestartet`,
		pl: `Nie rozpoczęto`,
		ro: `Nu a început`,
		ua: `Не розпочато`,
	},
	notPassed: {
		da: 'Ikke bestået',
		en: 'Not passed',
		se: `Misslyckades`,
		de: `Misslungen`,
		pl: `Nie powiodło się`,
		ro: `Eșuat`,
		ua: `Не вдалося`,
	},
	expires: {
		da: 'Udløber',
		en: 'Expires',
		se: `Upphör`,
		de: `Abläuft`,
		pl: `Wygasa`,
		ro: `Expiră`,
		ua: `Закінчується`,
	},
	expiresSoon: {
		da: 'Udløber snart',
		en: 'Expires soon',
		se: `Förfaller snart`,
		de: `Läuft bald ab`,
		pl: `Wygasa wkrótce`,
		ro: `Expiră în curând`,
		ua: `Термін дії скоро закінчується`,
	},
	manager: {
		da: 'Manager',
		en: 'Manager',
		se: `Föreståndare`,
		de: `Manager`,
		pl: `Menedżer`,
		ro: `Manager`,
		ua: `Голова`,
	},
	managerCoatId: {
		da: 'Default kittel',
		en: 'Default coat',
		se: `Standard kittel`,
		de: `Standard-Kessel`,
		pl: `Domyślny kociołek`,
		ro: `Cazan implicit`,
		ua: `Котел за замовчуванням`,
	},
	managerHairnetId: {
		da: 'Default hårnet',
		en: 'Default hairnet',
		se: `Standard hårnät`,
		de: `Standard-Haarnetz`,
		pl: `Domyślna siatka na włosy`,
		ro: `Plasă de păr implicită`,
		ua: `Сітка для волосся за замовчуванням`,
	},
	managerShirtId: {
		da: 'Default skjorte',
		en: 'Default shirt',
		se: `Standardskjorta`,
		de: `Standard-Shirt`,
		pl: `Domyślna koszula`,
		ro: `Cămașă implicită`,
		ua: `Cтандартна сорочка`,
	},
	background: {
		da: 'Baggrund',
		en: 'Background',
		se: `Bakgrund`,
		de: `Hintergrund`,
		pl: `Tło`,
		ro: `Fundal`,
		ua: `Фон`,
	},
	clouds: {
		da: 'Skyer',
		en: 'Clouds',
		se: `Molnen`,
		de: `Wolken`,
		pl: `Chmury`,
		ro: `Nori`,
		ua: `Хмари`,
	},
	birds: {
		da: 'Fugle',
		en: 'Birds',
		se: `Fåglar`,
		de: `Vögel`,
		pl: `Ptaki`,
		ro: `Păsări`,
		ua: `Птахи`,
	},
	surveys: {
		da: 'Surveys',
		en: 'Surveys',
		se: `Undersökningar`,
		de: `Umfragen`,
		pl: `Ankiety`,
		ro: `Sondajele`,
		ua: `Опитування`,
	}
};

export {
	courseUiTexts
};